const passwordFields = document.querySelectorAll('input[type=password]');
passwordFields.forEach(field => {
  const wrapper = field.parentNode;
  const action = wrapper.querySelector('.password-visibility');


  action.addEventListener('click', () => {
    field.type === 'password' ? field.type = 'text' : field.type = 'password';
    action.classList.toggle('password-visibility--show');
  });
});