import { SetSize } from './base/functions';
import { isMobile } from "./base/variables";

export const header = document.getElementById('header');
export let headerHeight = SetSize(header, 'header');

window.addEventListener('resize', () => headerHeight = SetSize(header, 'header'));

const headerContainer = header.querySelector('.container');

const dropdowns = header.querySelectorAll('.dropdown-nav');
const dropdownContainer = header.querySelector('.header__dropdown');

dropdowns.forEach(dropdown => {
  const nav = document.createElement('nav');
  const list = dropdown.querySelector('ul');

  nav.appendChild(list);
  dropdownContainer.appendChild(nav);

  // FIX Отрефакторить код
  dropdown.addEventListener('mouseover', () => {
    dropdown.classList.add('dropdown-nav--active');
    nav.classList.add('visible');
  });

  dropdown.addEventListener('mouseout', () => {
    dropdown.classList.remove('dropdown-nav--active');
    nav.classList.remove('visible');
  });

  nav.addEventListener('mouseover', () => {
    dropdown.classList.add('dropdown-nav--active');
    nav.classList.add('visible');
  });

  nav.addEventListener('mouseout', () => {
    dropdown.classList.remove('dropdown-nav--active');
    nav.classList.remove('visible');
  });
});


if (isMobile) header.classList.remove('header--hidden');


const modal = document.querySelector(".vacancy__modal");
if (modal) {
  setTimeout(() => {
    modal.style.right = "0";
  }, 4000);

  const closeBtn = document.querySelector(".vacancy__modal_close");
  closeBtn.addEventListener("click", function() {
    document.cookie = "vm-close=Y;path=/;max-age=2629743";
  });
  
  if (closeBtn) {
    const closeModal = () => {
      modal.style.right = "-353px";
    };
    closeBtn.addEventListener("click", closeModal);
  }

  const bell = document.getElementById("vacancy__modal-animate");
  if (bell) {
    const shakeBell = () => {
      bell.classList.add("vacansy-animate");

      bell.addEventListener("animationend", () => {
        bell.classList.remove("vacansy-animate");
      }, {once: true});
    };

    setInterval(shakeBell, 5000);
  }
  const closeMd = document.querySelector(".vacancy__modal_close");
  closeMd.addEventListener("click", function() {
    document.cookie = "vm-close=Y;";
  });
}