new Swiper('#slider-new .swiper', {
  slidesPerView: 'auto',
  spaceBetween: 20,

  navigation: {
    prevEl: "#slider-new .swiper-button-prev",
    nextEl: "#slider-new .swiper-button-next",
  },

  autoplay: {
    delay: 3000,
  },

  breakpoints: {
    800: {
      slidesPerView: 2,
    },

    1040: {
      slidesPerView: 3,
    },

    1320: {
      slidesPerView: 4,
    }
  }
});

new Swiper('#slider-discount .swiper', {
  slidesPerView: 'auto',
  spaceBetween: 20,

  navigation: {
    prevEl: "#slider-discount .swiper-button-prev",
    nextEl: "#slider-discount .swiper-button-next",
  },

  autoplay: {
    delay: 3000,
  },

  breakpoints: {
    800: {
      slidesPerView: 2,
    },

    1040: {
      slidesPerView: 3,
    },

    1320: {
      slidesPerView: 4,
    }
  }
});

new Swiper('#slider-vacansy .swiper', {
  slidesPerView: 1,
  spaceBetween: 20,

  navigation: {
    prevEl: "#slider-vacansy .swiper-button-prev",
    nextEl: "#slider-vacansy .swiper-button-next",
  },

  pagination: {
    el: "#slider-vacansy .swiper-pagination",
    clickable: true,
  },

  autoplay: {
    delay: 3000,
  }
});

