
import { isMobile } from "../base/variables";

const hero = document.getElementById('hero');
if (hero) {
  const wrapper = hero.querySelector('.container--wide');

  new LazyLoad({
    elements_selector: ".hero__building .lazy__item",

    callback_loaded: (trigger) => {
      const container = trigger.closest("div");
      const logo = container.querySelector(".hero__logo");

      if (isMobile) {
        logo.classList.add('show');
      }

      else {
        logo.classList.add('animate');
        wrapper.classList.add('animate');
      }
    },
  });
}