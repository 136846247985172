import { modalParams } from "./base/settings";

const modal = document.getElementById('modal-city');

const switcher = document.querySelector('.switch-city');
const list = switcher.querySelector('.switch-city__list');
const cities = list.querySelectorAll('li');

UpdateContact(cityContact);

list.addEventListener('click', (e) => {
  const target = e.target;
  if (target.tagName != 'LI') return;

  list.querySelector('.active').removeAttribute('class');
  target.classList.add('active');

  UpdateContact(cityContact);

  if (modal) MicroModal.close(modal.id, modalParams);
});

function UpdateContact(data) {
  const groups = document.querySelectorAll('[data-switch=group]');
  const emails = document.querySelectorAll('[data-switch=email]');
  const city = document.querySelectorAll('[data-switch=city]');

  Object.entries(data).forEach(([key, value], index) => {
    cities[index].textContent = value.locale;

    if (cities[index].classList.contains('active')) {
      city.forEach(item => item.textContent = value.locale);

      emails.forEach(email => {
        const contact = value.email;

        email.href = `mailto:${contact}`;
        email.textContent = contact;
      });

      groups.forEach(group => {
        const phones = group.querySelectorAll('[data-switch=phone]');

        phones.forEach((phone, index) => {
          const contact = value.phone[index];

          if (contact) {
            phone.style.removeProperty('display');
            phone.href = `tel:+${Format(contact)}`;
            phone.textContent = contact;
          }

          else phone.style.display = 'none';
        });
      });
    }
  });
}

function Format(target) {
  let phone = target.replaceAll(/[- ()]/g, '');

  if (phone[0] == 8) {
    phone = phone.substring(1);
    phone = '7' + phone;
  }

  return phone;
}