const page = document.getElementById('calculator-mass');
if (page) {
  const selects = page.querySelectorAll('select');

  selects.forEach(select => new NiceSelect.bind(select));

  const lastSelects = page.querySelectorAll('.nice-select');
  for (let index = lastSelects.length - 4; index < lastSelects.length; index++) {
    lastSelects[index].classList.add('nice-select--reverse');
  }
}