export const StyleСlass = {
  "mobile": {
    "open": "mobile-menu--open",
  },

  "body": {
    "overflow": "disable-scroll",
  },

  "catalog": {
    "open": "catalog-menu--open",
  },
};

export const modalParams = {
  awaitCloseAnimation: true,
  disableFocus: true,
  disableScroll: true,

  onClose: modal => {
    const overflowContainer = modal.querySelectorAll('.custom-scrollbar');
    overflowContainer.forEach(container => container.scrollTop = 0);
    window.currentModal = null;
  },

  onShow: modal => {
    if (window.currentModal) {
      MicroModal.close(window.currentModal);
    }

    else {
      window.currentModal = modal.id;
    }
  }
};

export const scrollParams = {
  speed: 500,
  speedAsDuration: true,
  updateURL: false,
};

export const breakpoint = {
  'lg': '(min-width: 1040px)',
  'xl': '(min-width: 1320px)',
};
