import { Collapse } from '../../misc/collapse';

const accordions = document.querySelectorAll('.accordion');
accordions.forEach(accordion => {
  const header = accordion.querySelector('.accordion__header');
  const body = accordion.querySelector('.accordion__body');
  const collapse = new Collapse(body, 450);
  body.classList.add('collapse');

  header.addEventListener('click', () => {
    collapse.toggle();
    accordion.classList.toggle('accordion--open');
  });

  if (accordion.dataset.open !== undefined) {
    accordion.classList.add('accordion--open');
    body.classList.add('collapse_show');
  };
});