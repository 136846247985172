const tabSlider = new Swiper('.contact__tabs .swiper', {
  slidesPerView: 1,
  spaceBetween: 30,
  allowTouchMove: false,

  autoHeight: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
});

const action = document.querySelector('.contact__action');

if (action) {
  const buttons = action.querySelectorAll('.contact__button');
  buttons.forEach((button, index) => button.dataset.index = index);

  action.addEventListener('click', (e) => {
    const target = e.target;
    if (target.closest('button')) {
      tabSlider.slideTo(target.dataset.index);
      action.querySelector('.active').classList.remove('active');
      target.classList.add('active');
    }
  });
}
