const editorBlock = document.querySelectorAll('[data-editor]');

editorBlock.forEach(editor => {
  const tables = editor.querySelectorAll('table');
  const buttons = editor.querySelectorAll('button');

  tables.forEach(table => {
    const currentHTML = table.outerHTML;

    const newHTML = document.createElement('div');
    newHTML.style.overflow = "auto";
    newHTML.innerHTML = currentHTML;

    table.parentNode.insertBefore(newHTML, table);
    table.remove();
  });

  buttons.forEach(button => {
    if (!button.classList.contains('button')) button.classList.add('button', 'button-primary');
  });
});
