import { StyleСlass } from './base/settings';
import { breakpoint } from './base/settings';
import { MediaQuery } from './base/functions';
import { Collapse } from '../misc/collapse';

const menu = document.querySelector('.page__menu');

if (menu) {
  const catalog = menu.querySelector('.catalog-menu');
  const personal = menu.querySelector('.catalog-menu--personal');

  if (personal && !MediaQuery(breakpoint.lg)) {
    personal.classList.add('catalog-menu--collapsed', 'catalog-menu--fixed');

    const pageTitle = document.querySelector('.page__title');
    pageTitle.parentNode.insertBefore(personal, pageTitle.nextSibling);
  }

  if (catalog.classList.contains('catalog-menu--collapsed')) {
    const nav = catalog.querySelector('.catalog-menu__nav nav');
    const collapse = new Collapse(nav, 300);

    nav.classList.add('collapse');

    window.addEventListener('click', (e) => {
      const target = e.target.closest('.catalog-menu');

      if (target == catalog) {
        catalog.classList.toggle(StyleСlass.catalog.open);
        collapse.toggle();
      }

      else {
        catalog.classList.remove(StyleСlass.catalog.open);
        collapse.hide();
      }
    });
  }

  if (menu.classList.contains('side-menu--fixed')) {
    menu.style.width = menu.parentElement.offsetWidth + 'px';
    menu.style.opacity = 1;
  }
}

