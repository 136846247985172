const search = document.querySelector('.header__search .search');
if (search) {
  const controller = search.querySelector('input');
  const text = search.querySelector('span');
  const defaultText = text.textContent;

  controller.addEventListener('change', () => {
    if (controller.checked) text.textContent = text.dataset.text;
    else text.textContent = defaultText;
  });
}