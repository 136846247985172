import { footerHeight } from "./footer";
const scrollUp = document.querySelector('.page__scroll-top');

window.addEventListener('scroll', () => {
  const pageHeight = document.body.scrollHeight;

  if (window.scrollY > 600 && (window.innerHeight + window.scrollY) < (pageHeight - footerHeight - 40))
    scrollUp.classList.remove('page__scroll-top--hidden');
  else
    scrollUp.classList.add('page__scroll-top--hidden');
});


const preloader = document.getElementById('page-loader');
if (preloader)
  document.addEventListener("DOMContentLoaded", function () {
    window.setTimeout(function () {
      preloader.classList.add('page-loader--hide');
    }, 500);
  });
