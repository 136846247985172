const counter = document.querySelectorAll('.counter');
counter.forEach(item => {
  const value = item.querySelector('.counter__value');
  let count = 1;

  document.addEventListener('click', (e) => {
    const target = e.target;

    if (target == item.querySelector('.counter__minus')) {
      count = CheckValue(--count);
      value.textContent = count;
    }

    else if (target == item.querySelector('.counter__plus')) {
      value.textContent = ++count;
    }
  });


  const CheckValue = (value) => (value > 0) ? value : 1;
});