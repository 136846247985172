import { header } from './header';
import { SetSize } from './base/functions';
import { StyleСlass } from './base/settings';
import { Collapse } from '../misc/collapse';

const headerBurger = header.querySelector('.hamburger');

const menu = document.getElementById('mobile-menu');
const mobileHeader = menu.querySelector('.mobile-menu__header');
const overlay = menu.querySelector('.mobile-menu__overlay');
const close = menu.querySelector('.mobile-menu__close');

const dropdowns = menu.querySelectorAll('.dropdown-nav');

SetSize(mobileHeader, 'mobile-header');

headerBurger.addEventListener('click', () => Menu('open'));
overlay.addEventListener('click', () => Menu('close'));
close.addEventListener('click', () => Menu('close'));

window.addEventListener('resize', () => {
  SetSize(mobileHeader, 'mobile-header');
  Menu('close');
});

function Menu(state) {
  if (state === 'open') {
    document.body.classList.add(StyleСlass.body.overflow);
    menu.classList.add(StyleСlass.mobile.open);
  }

  else if (state === 'close') {
    document.body.classList.remove(StyleСlass.body.overflow);
    menu.classList.remove(StyleСlass.mobile.open);
  }
}

dropdowns.forEach(dropdown => {
  const headerLink = dropdown.querySelector('.nav__link');

  const ul = dropdown.querySelector('ul');
  const collapse = new Collapse(ul, 300);
  ul.classList.add('collapse');

  const newDropdown = TransformDropdown(headerLink);

  newDropdown.addEventListener('click', () => {
    collapse.toggle();
    newDropdown.parentNode.classList.toggle('dropdown-nav--active');
  });
});

function TransformDropdown(target) {
  const currentHTML = target.outerHTML;
  const newHTML = document.createElement('div');
  newHTML.classList.add('dropdown-nav__header');
  newHTML.innerHTML = currentHTML;

  target.parentNode.insertBefore(newHTML, target);
  target.remove();

  return newHTML;
}