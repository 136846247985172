new Swiper('#slider-partners .swiper', {
  loop: true,
  slidesPerView: 2,
  spaceBetween: 30,

  navigation: {
    prevEl: "#slider-partners .swiper-button-prev",
    nextEl: "#slider-partners .swiper-button-next",
  },

  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },

  breakpoints: {
    800: {
      slidesPerView: 3,
    },

    1040: {
      slidesPerView: 4,
    },

    1320: {
      slidesPerView: 5,
      spaceBetween: 40,
    }
  }
});

const galleryThumb = new Swiper('.gallery-product__thumb .swiper', {
  slidesPerView: 3,
  spaceBetween: 20,

  navigation: {
    prevEl: ".gallery-product__thumb .swiper-button-prev",
    nextEl: ".gallery-product__thumb .swiper-button-next",
  },

  breakpoints: {
    1320: {
      slidesPerView: 4,
    }
  }
});


const gallery = new Swiper('.gallery-product__view .swiper', {
  slidesPerView: 1,
  spaceBetween: 30,

  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },

  thumbs: {
    swiper: galleryThumb
  }
});

