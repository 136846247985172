const page = document.getElementById('quick-order');

if (page) {
  const functions = page.querySelectorAll('[data-add]');
  const selects = page.querySelectorAll('select');

  selects.forEach(select => new NiceSelect.bind(select));

  functions.forEach(func => {
    const wrapper = func.parentNode.querySelector('.input__wrapper');
    const row = wrapper.querySelector('.input__row');

    func.addEventListener('click', () => {
      const clone = row.cloneNode(true);
      wrapper.appendChild(clone);
    });
  });
}