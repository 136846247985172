import { modalParams, scrollParams } from "./base/settings";
import { isMobile } from "./base/variables";

export const lazyLoadInstance = new LazyLoad({
  elements_selector: ".lazy .lazy__item",

  callback_loaded: (trigger) => {
    const container = trigger.closest(".lazy");
    container.classList.remove('lazy--preloader');

    const loader = container.querySelector(".preloader");

    if (loader) loader.classList.add("preloader--hide");
  },
});

MicroModal.init(modalParams);

new SmoothScroll('a[href*="#"]', scrollParams);

AOS.init({
  once: true,
  disable: isMobile,
});