import { modalParams } from "../base/settings";

const tabs = new Swiper('.single-product__interaction .swiper', {
  slidesPerView: 1,
  spaceBetween: 20,
  autoHeight: true,

  observer: true,
  allowTouchMove: false,

  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },

  on: {
    // init(instanse) {
    //   instanse.slideTo(1);
    // },

    afterInit(instanse) {
      const control = instanse.el.querySelector('.swiper-control');
      const buttons = control.querySelectorAll('.button');

      buttons.forEach((button, index) => button.dataset.index = index);
      buttons[instanse.activeIndex].classList.add('active');

      control.addEventListener('click', (e) => {
        const target = e.target.closest('.button');
        if (target) {
          control.querySelector('.active').classList.remove('active');
          target.classList.add('active');

          instanse.slideTo(target.dataset.index);
        }
      });

      instanse.updateAutoHeight(300);
    }
  }
});

const order = document.querySelector('.order-product');
if (order) {
  const paramsPanel = order.querySelector('.order-product__params');
  const selects = paramsPanel.querySelectorAll('select');

  const options = { searchable: true, placeholder: 'не задано', searchtext: 'Поиск', selectedtext: 'Выбрано' };

  selects.forEach(select => new NiceSelect.bind(select, options));

  const observer = new MutationObserver(UpdateTabs);
  observer.observe(order, {
    childList: true,
    subtree: true,
  });

  function UpdateTabs(mutationList) {
    mutationList.forEach((mutation) => {
      if (mutation.type == 'childList') {
        MicroModal.init(modalParams);
        tabs.updateAutoHeight(300);
      }
    });
  }
}